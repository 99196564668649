<template>
  <div>
    <v-row dense>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>{{ $t('ztjrs') }}</small>
          <div>
            <strong class="mr-1 text--primary">{{ teams.total_invites }}</strong>
            <small>{{ $t('ren') }}</small>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>{{ $t('yjrs') }}</small>
          <div>
            <strong class="mr-1 text--primary">{{ teams.tier1_invites }}</strong>
            <small>{{ $t('ren') }}</small>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>{{ $t('ejrs') }}</small>
          <div>
            <strong class="mr-1 text--primary">{{ teams.tier2_invites }}</strong>
            <small>{{ $t('ren') }}</small>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>{{ $t('sjrs') }}</small>
          <div>
            <strong class="mr-1 text--primary">{{ teams.tier3_invites }}</strong>
            <small>{{ $t('ren') }}</small>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-card
      flat
      color="box_bg"
      class="mt-3"
    >
      <v-card-text>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <v-text-field
              v-model="username"
              :label="$t('yhm')"
              dense
              outlined
              hide-details
            />
          </v-col>
          <!--
          <v-col
            cols="6"
            md="3"
          >
            <v-select
              v-model="tier"
              :items="tieritems"
              :label="$t('qbdj')"
              no-data-text=""
              dense
              outlined
              hide-details
            />
          </v-col>
          -->
          <v-col
            cols="6"
            md="3"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateRangeText"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="$t('zcrq')"
                  readonly
                  v-bind="attrs"
                  dense
                  outlined
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                scrollable
                range
                color="indigo"
                locale="pt-BR"
                class="box_bg"
              >
                <v-btn
                  text
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              depressed
              color="primary"
              @click="invite_team_list"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              {{ $t('sousuo') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <div
        class="overflow-x-auto"
        style="white-space: nowrap;"
      >
        <div
          v-if="list.length==0"
          class="text-center text--disabled my-10"
        >
          <v-icon
            x-large
            class="text--disabled"
          >
            mdi-archive-search-outline
          </v-icon>
          <small class="d-block text-center">{{ $t('myrhjl') }}</small>
        </div>
        <v-simple-table v-if="list.length>0">
          <thead class="nav_bg">
            <tr>
              <th>{{ $t('yhm') }}</th>
              <!--<th>{{ $t('dengji') }}</th>-->


              <th>{{ $t('zyqjj') }}</th>
              <th>{{ $t('zcke') }}</th>
              <th>{{ $t('zckyj') }}</th>
              <th>{{ $t('ztze') }}</th>
              <th>{{ $t('ztzyj') }}</th>

              <th>{{ $t('zhuangtai') }}</th>
              <th>{{ $t('zcrq') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in list"
              :key="'t'+index"
            >
              <td><strong>{{ item.area_code }} {{ item.phone }}</strong></td>
              <!--<td>Lv{{ item.afflevel }}</td>-->

              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.total_invite_award }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.total_deposit }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.total_depositcommission }}
              </td>
              <td>
                {{ item.total_bet }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.total_betcommission }}
              </td>

              <td><span v-if="item.status==1">active</span><span v-else>denied</span></td>
              <td>{{ $dayjs.unix(item.reg_time).format('DD/MM/YYYY') }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-pagination
            v-model="page"
            :length="pagecount"
            :total-visible="7"
            @input="invite_team_list"
          />
          <v-spacer />
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'InviteTeam',
  components:{
  },
  props: {

  },
  data: () => ({
    teams: {
      "tier1_invites": 0,
      "tier2_invites": 0,
      "tier3_invites": 0,
      "total_invites": 0
    },
    username: '',
    tieritems: [
      {'text': 'All', 'value': ''},
      {'text': 'Tier 1', 'value': '1'},
      {'text': 'Tier 2', 'value': '2'},
      {'text': 'Tier 3', 'value': '3'}
    ],
    tier: '1',
    menu: false,
    dates: [],
    page: 1,
    pagecount: 1,
    list: [
      
    ]
  }),
  computed: {
    dateRangeText:{
      // getter
      get: function () {
        //this.dates.sort()//不能再computed中改变页面变量的值，如果需要改变，请使用watch
        return this.dates.join(' ~ ')
      },
      //[Vue warn]: Computed property "dateRangeText" was assigned to but it has no setter
      // setter
      set: function (newVal) {
        console.log(newVal)
      }
    }
  },
  watch:{
    dateRangeText: {
      handler(newVal, oldVal) {
        this.dates.sort()
      }
    },
  },
  created() {
    this.invite_team()
  },
  mounted() {
    this.invite_team_list()
  },
  methods: {
    invite_team() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.invite_team(paramObj).then((response) => {
        if(response.code==200){
          this.teams = response.data
        }else{
          
        }
      })
    },
    invite_team_list() {
      let paramObj = {
        page:this.page,
        member_id: this.getStorage('member_id'),
        username: this.username,
        tier: this.tier,
        reg_time: this.dates
      }
      this.$server.invite_team_list(paramObj).then((response) => {
        if(response.code==200){
          this.pagecount = response.data.last_page
          this.list = response.data.data
        }else{
          
        }
      })
    }
  },
};
</script>