<template>
  <div>
    <v-row
      dense
      class="justify-center"
    >
      <v-col
        cols="12"
        md="8"
        order-md="1"
      >
        <v-card
          flat
          color="#0766ff"
        >
          <v-img src="../../assets/img/Invite-activity-bg.png">
            <div class="d-flex justify-center align-center fill-height">
              <div class="text-center white--text">
                <v-icon
                  large
                  color="white"
                >
                  mdi-cloud-upload
                </v-icon>
                <h4>{{ $t('scjt') }}</h4>
                <h5>{{ $t('lq_x') }}<strong class="mx-1 yellow--text">R$ 12.5</strong>{{ $t('x_jj') }}</h5>
              </div>
            </div>
          </v-img>
          <div class="mt-n3 text-center">
            <v-chip
              small
              dark
              color="#0766ff"
            >
              {{ $t('scjtbt') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
        order-md="2"
      >
        <v-card
          color="box_bg"
          flat
        >
          <v-card-title class="py-3 text-body-1">
            {{ $t('yqpyzq') }}
          </v-card-title>
          <v-card-text>
            <v-sheet
              rounded
              color="app_bg"
              class="pa-3"
            >
              <v-text-field
                flat
                dense
                solo
                hide-details
                readonly
                background-color="btn_bg"
                :value="invite_url"
                append-icon="mdi-content-copy"
                @click:append="doCopy(invite_url)"
              />

              <div class="mt-3 text--secondary pl-4">
                Referências de conteúdo:
              </div>
              <v-carousel
                cycle
                height="200"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="(item, index) in copywritinglist"
                  :key="'c'+index"
                >
                  <v-card-text class="btn_bg rounded text--disabled text-caption">
                    <div class="text-center mb-2">
                      <v-btn
                        depressed
                        small
                        @click="doCopy(item.txt)"
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-content-copy
                        </v-icon>
                        {{ $t('yjfz') }}
                      </v-btn>
                    </div>

                    <code class="transparent">{{ item.txt }}</code>
                  </v-card-text>
                </v-carousel-item>
              </v-carousel>
              <div
                v-if="false"
                class="my-3"
              >
                <center>
                  <v-btn
                    :disabled="!fileurl"
                    depressed
                    dark
                    outlined
                    color="cyan"
                    @dblclick="dblclick"
                    @click="downloadvideo"
                  >
                    {{ $t('bcspxz') }}
                  </v-btn>
                </center>
              </div>
              <div v-if="false">
                <center>
                  <video
                    v-if="fileurl"
                    width="180"
                    muted
                    autoplay="autoplay"
                    controls
                  >
                    <source
                      :src="fileurl"
                      type="video/mp4"
                    >
                  </video>
                </center>
              </div>
              <div
                v-if="!$store.state.is_pc"
                class="text-center pt-2"
              >
                <v-btn
                  icon
                  x-large
                  color="#0766ff"
                  :href="fburl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-large
                  color="pink"
                  :href="insurl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-large
                  color="#25d366"
                  :href="whatsappurl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  color="grey darken-4"
                  :href="kwaiurl"
                  target="_blank"
                  rel="noopener"
                  class="ml-2"
                >
                  <v-img
                    width="56"
                    src="../../assets/img/icons/logo-kwai.svg"
                  />
                </v-btn>
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="8"
        order-md="3"
      >
        <v-card
          color="box_bg"
          flat
        >
          <v-card-title class="py-3 text-body-1">
            {{ $t('scjt') }}
          </v-card-title>
          <v-card-text v-if="allowupload && todaycount<5 && attime && countdownhour3<1">
            <v-row
              dense
              justify="center"
              class="align-center"
            >
              <v-col
                cols="4"
                class="text-center"
              >
                <v-card
                  v-show="f1"
                  flat
                  color="nav_bg"
                >
                  <v-img
                    max-height="150"
                    :aspect-ratio="9/11"
                    :src="f1"
                  >
                    <div class="d-flex justify-end">
                      <v-btn
                        fab
                        x-small
                        depressed
                        dark
                        color="grey darken-1"
                        @click="del('1')"
                      >
                        <v-icon
                          small
                          dark
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-2"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
                <v-btn
                  v-show="!file1"
                  fab
                  depressed
                  x-large
                  @click="chooseImage('1')"
                >
                  <v-icon>mdi-camera-plus</v-icon>
                </v-btn>
                <v-file-input
                  ref="ff1"
                  v-model="file1"
                  hide-input
                  accept="image/png, image/jpeg, image/jpg"
                  prepend-icon="mdi-camera-plus"
                  @change="onSelectFile('1')"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-card
                  v-show="f2"
                  flat
                  color="nav_bg"
                >
                  <v-img
                    max-height="150"
                    :aspect-ratio="9/11"
                    :src="f2"
                  >
                    <div class="d-flex justify-end">
                      <v-btn
                        fab
                        x-small
                        depressed
                        dark
                        color="grey darken-1"
                        @click="del('2')"
                      >
                        <v-icon
                          small
                          dark
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-2"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
                <v-btn
                  v-show="!file2"
                  fab
                  depressed
                  x-large
                  @click="chooseImage('2')"
                >
                  <v-icon>mdi-camera-plus</v-icon>
                </v-btn>
                <v-file-input
                  ref="ff2"
                  v-model="file2"
                  hide-input
                  accept="image/png, image/jpeg, image/jpg"
                  prepend-icon="mdi-camera-plus"
                  @change="onSelectFile('2')"
                />
              </v-col>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-card
                  v-show="f3"
                  flat
                  color="nav_bg"
                >
                  <v-img
                    max-height="150"
                    :aspect-ratio="9/11"
                    :src="f3"
                  >
                    <div class="d-flex justify-end">
                      <v-btn
                        fab
                        x-small
                        depressed
                        dark
                        color="grey darken-1"
                        @click="del('3')"
                      >
                        <v-icon
                          small
                          dark
                        >
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-2"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
                <v-btn
                  v-show="!file3"
                  fab
                  depressed
                  x-large
                  @click="chooseImage('3')"
                >
                  <v-icon>mdi-camera-plus</v-icon>
                </v-btn>
                <v-file-input
                  ref="ff3"
                  v-model="file3"
                  hide-input
                  accept="image/png, image/jpeg, image/jpg"
                  prepend-icon="mdi-camera-plus"
                  @change="onSelectFile('3')"
                />
              </v-col>
            </v-row>
            <div class="pt-3">
              <v-text-field
                v-model="link"
                flat
                dense
                filled
                rounded
                label="Post Link"
                :hide-details="link_error?false:true"
                :error-messages="link_error"
                clearable
                background-color="nav_bg"
                prepend-inner-icon="mdi-link-variant"
                placeholder="Post Link..."
                class="rounded"
              />
            </div>
          </v-card-text>

          <v-card-text
            v-if="allowupload && todaycount<5 && attime && countdownhour3>0"
            class="text-center py-5 primary--text"
          >
            <v-chip>
              <v-icon
                left
              >
                mdi-clock-time-eight-outline
              </v-icon>
              {{ daojishi }}
            </v-chip>
            <div class="mt-3">
              {{ $t('xctjsj') }}
            </div>
          </v-card-text>

          <v-card-text
            v-if="allowupload && todaycount>4 && attime && countdownhour3<1"
            class="text-center py-5 primary--text"
          >
            <v-icon
              x-large
              color="primary"
              class="mb-3"
            >
              mdi-clock-time-eight-outline
            </v-icon>
            <div>{{ $t('ytzntj2c') }}</div>
          </v-card-text>

          <v-card-text
            v-if="!attime"
            class="text-center py-5 primary--text"
          >
            <v-icon
              x-large
              color="primary"
              class="mb-3"
            >
              mdi-clock-time-eight-outline
            </v-icon>
            <div>{{ $t('xzwfsy') }}</div>
          </v-card-text>

          <v-card-actions v-if="allowupload && attime">
            <v-btn
              :disabled="todaycount>4 || countdownhour3>0"
              depressed
              block
              color="primary"
              :loading="loading"
              @dblclick="dblclick"
              @click="submitrecords"
            >
              {{ $t('tijiao') }}
            </v-btn>
          </v-card-actions>

          <v-divider
            v-if="list.length>0"
            class="opacity-3 mt-3"
          />

          <v-card-title
            v-if="list.length>0"
            class="py-3 text-body-1"
          >
            {{ $t('lsjl') }}
          </v-card-title>

          <v-list
            color="box_bg"
            class="text-caption pt-0"
          >
            <div
              v-for="(item, index) in list"
              :key="'h'+index"
            >
              <v-divider class="opacity-3" />
              <v-list-item>
                <v-list-item-action>
                  {{ item.created }}
                </v-list-item-action>
                <v-list-item-content>
                  <div>
                    <img
                      v-for="(img, i) in item.imgs"
                      :key="'i'+i"
                      width="50"
                      class="mx-1"
                      :src="img.url"
                    >
                  </div>
                  <v-chip
                    x-small
                    color="app_bg"
                    class="mt-1 text--disabled"
                  >
                    <v-icon
                      x-small
                      left
                    >
                      mdi-link-variant
                    </v-icon>
                    {{ item.link }}
                  </v-chip>
                </v-list-item-content>
                <v-list-item-action
                  v-if="item.status==0"
                  class="text-right"
                >
                  {{ $t('daishenhe') }}
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==1"
                  class="text-right"
                >
                  <div>
                    <div class="orange--text font-weight-medium">
                      + R$ {{ item.money }}
                    </div>
                    <span class="green--text">{{ $t('yifafang') }}</span>
                  </div>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==-1"
                  class="text-right"
                >
                  <div class="text-center">
                    <v-btn
                      icon
                      small
                      @click="tipReject = true"
                    >
                      <v-icon small>
                        mdi-tooltip-question-outline
                      </v-icon>
                    </v-btn>
                    <div class="red--text">
                      {{ $t('weitongguo') }}
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
        order-md="4"
      >
        <v-card
          flat
          color="box_bg"
        >
          <v-card-title class="py-3 text-body-1">
            {{ $t('guize') }}
          </v-card-title>

          <v-card-text>
            <div>{{ $t('upimg_a01') }}</div>
            <div class="text--disabled">
              {{ $t('upimg_a02') }}
            </div>
            <div class="font-weight-medium">
              {{ $t('upimg_a03') }}
            </div>

            <ol class="mt-3">
              <li>{{ $t('upimg_b01') }}</li>
              <li>{{ $t('upimg_b02') }}</li>
              <li>{{ $t('upimg_b03') }}</li>
              <li>{{ $t('upimg_b04') }}</li>
            </ol>
          </v-card-text>
        
          <v-divider class="opacity-3" />

          <v-card-title class="py-3 text-body-1">
            {{ $t('jlck') }}
          </v-card-title>

          <v-card-text>
            <v-carousel
              cycle
              height="720"
            >
              <v-carousel-item
                v-for="n in 5"
                :key="'i'+n"
                :src="require('@/assets/img/pic/post-0'+n+'.png')"
              />
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="tipReject"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          Conselho correto:
        </v-card-title>
        <v-card-text>
          <ol>
            <li>Por favor poste no grupo público do Facebook</li>
            <li>O conteúdo publicado precisa incluir seu link de indicação e um texto descritivo</li>
            <li>Por favor, não carregue outras fotos irrelevantes. Cada usuário só pode fazer upload uma vez por dia, por favor, valorize isso</li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="tipReject = false"
          >
            {{ $t('zdl') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'InviteActivity',
    components:{
    },
    props: {
  
    },
    data: () => ({
      fileurl: '',
      file: '',
      loading: false,
      allowupload: false,
      list: [],
      copywritinglist: [],
      invite_url: '',
      file1: null,
      file2: null,
      file3: null,
      f1: null,
      f2: null,
      f3: null,
      link: '',
      link_error: '',
      todaycount: 5,
      tipReject: false,
      attime: true,
      countdownhour3: 0,
      hour3timer: null,
      daojishi: '',
      fburl: 'fb://',
      insurl: 'instagram://user?username=cbf_futebol',
      whatsappurl: 'whatsapp://send?',
      kwaiurl: 'kwai://home'
    }),
    computed: {

    },
    watch:{

    },
    created() {
      this.copywriting()
      this.getavideo()

      if( (/iphone|ipod|ipad/.test( window.navigator.userAgent.toLowerCase() )) && (/safari/.test( window.navigator.userAgent.toLowerCase() ))==false ){
        this.fburl = "pgb://"+encodeURIComponent(this.fburl)
        this.insurl = "pgb://"+encodeURIComponent(this.insurl)
        this.whatsappurl = "pgb://"+encodeURIComponent(this.whatsappurl)
        this.kwaiurl = "pgb://"+encodeURIComponent(this.kwaiurl)
      }
    },
    mounted() {
      this.promorecords()
    },
    methods: {
      copywriting() {
        let paramObj = {
          member_id: this.getStorage('member_id'),
        }
        this.$server.copywriting(paramObj).then((response) => {
          if(response.code==200){
            this.invite_url = response.data.invite_url
            this.copywritinglist = response.data.copywriting
            //this.invite_url = window.location.protocol+"//"+window.location.host+'/?code='+this.invite_code
          }
        })
      },
      promorecords() {
        let paramObj = {
          member_id: this.getStorage('member_id'),
        }
        this.$server.promorecords(paramObj).then((response) => {
          if(response.code==200){
            this.list = response.data.list
            this.allowupload = response.data.allowupload
            this.todaycount = response.data.todaycount
            this.countdownhour3 = response.data.countdownhour3
            this.attime = response.data.attime
            this.hour3countdown()
          }
        })
      },
      hour3countdown() {
        this.hour3timer = setInterval(() => {
          if (this.countdownhour3 > 0) {
            this.countdownhour3--
            let hours = Math.floor(this.countdownhour3 / 3600)
            let minutes = Math.floor(this.countdownhour3 / 60) % 60
            let seconds = this.countdownhour3 % 60
            this.daojishi = hours+':'+minutes+':'+seconds
          }else{
            this.daojishi = ''
            clearInterval(this.hour3timer)
            this.hour3timer = null
          }
        }, 1000)
      },
      onSelectFile(i) {
        if(this['file'+i].size>20000000){
          this['file'+i] = null
          this.$snackbar.error('O tamanho da imagem deve ser inferior a 20 MB')
          return false
        }
        let paramObj = {
          member_id: this.getStorage('member_id'),
          file: this['file'+i]
        }
        this.$server.upload(paramObj).then((response) => {
          if(response.code==200){
            this['f'+i] = response.data.url
          }else{
            this['file'+i] = null
            this['f'+i] = null
            this.$snackbar.error(response.msg)
          }
        })
      },
      submitrecords() {
        this.loading = true
        if(this.f1==null && this.f2==null && this.f3==null){
          this.$snackbar.error(this.$t('zs1zt'))
          this.loading = false
          return false
        }
        if(!this.link){
          this.link_error = this.$t('tgljbnwk')
          this.loading = false
          return false
        }
        if(!(/^https?:\/\/.+/.test(this.link))){
          this.link_error = this.$t('ljgscw')
          this.loading = false
          return false
        }
        this.link_error = ''
        let paramObj = {
          member_id: this.getStorage('member_id'),
          imgs: [this.f1, this.f2, this.f3],
          link: this.link,
        }
        this.$server.submitrecords(paramObj).then((response) => {
          if(response.code==200){
            this.file1 = null
            this.file2 = null
            this.file3 = null
            this.f1 = null
            this.f2 = null
            this.f3 = null
            this.link = ''
            this.link_error = ''
            this.promorecords()
          }else{
            if(response.code==936){
              this.link_error = this.$t('tgljycz')
            }
            if(response.code==938){
              this.link_error = this.$t('xctjsj')
            }
          }
          this.loading = false
        })
      },
      chooseImage (i) {
        this.$refs['ff'+i].$refs.input.click()
      },
      dblclick() {
        console.log('dblclick')
      },
      del(i) {
        this['file'+i] = null
        this['f'+i] = null
      },
      getavideo() {
        let paramObj = {
          member_id: this.getStorage('member_id'),
        }
        this.$server.getavideo(paramObj).then((response) => {
          if(response.code==200){
            this.fileurl = response.data.fileurl
            this.file = response.data.file
          }
        })
      },
      downloadvideo() {
        location.href = process.env.VUE_APP_API_BASE_URL + 'index/downloadvideo?filename=' + this.file;
      },
      doCopy: function (text) {
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('fzcg'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  };
</script>
<style scoped>
.v-file-input{
  display:none
}
</style>