<template>
  <div>
    <v-card
      v-if="member_id>0"
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-row dense>
        <v-col
          cols="12"
          md="6"
        >
          <v-card-title>
            {{ $t('wdljyj') }}
            <v-spacer />
            <small>
              R$ <strong class="orange--text">{{ total_award }}</strong>
              <v-btn
                icon
                @click="changechip('dashboard')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </small>
          </v-card-title>
          <v-card-text class="text-center">
            <v-container class="app_bg rounded">
              <v-row class="py-1">
                <v-col>
                  <div><v-icon>mdi-account-group</v-icon></div>
                  <small class="text--disabled">{{ $t('yqjj') }}</small>
                  <div class="font-weight-medium">
                    R$ {{ total_invite_award }}
                  </div>
                </v-col>
                <v-col>
                  <div><v-icon>mdi-wallet-plus</v-icon></div>
                  <small class="text--disabled">{{ $t('ckyj') }}</small>
                  <div class="font-weight-medium">
                    R$ {{ total_depositcommission }}
                  </div>
                </v-col>
                <v-col>
                  <div><v-icon>mdi-poker-chip</v-icon></div>
                  <small class="text--disabled">{{ $t('tzyj') }}</small>
                  <div class="font-weight-medium">
                    R$ {{ total_betcommission }}
                  </div>
                </v-col>
              </v-row>
              <small class="text--disabled">{{ $t('mtsdyj') }}</small>
            </v-container>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card-title>
            {{ $t('yqpyzq') }}
          </v-card-title>
          <v-card-text>
            <v-sheet
              rounded
              color="app_bg"
              class="pa-3 pb-0"
            >
              <v-text-field
                flat
                dense
                solo
                hide-details
                readonly
                :value="invite_url"
                append-icon="mdi-content-copy"
                @click:append="doCopy(invite_url)"
              />
              <div
                v-if="!$store.state.is_pc"
                class="text-center pt-2"
              >
                <v-btn
                  icon
                  x-large
                  color="#0766ff"
                  :href="fburl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-large
                  color="pink"
                  :href="insurl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-large
                  color="#25d366"
                  :href="whatsappurl"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn
                  fab
                  depressed
                  x-small
                  color="grey darken-4"
                  :href="tturl"
                  target="_blank"
                  rel="noopener"
                  class="ml-2"
                >
                  <v-img
                    width="24"
                    src="../../assets/img/icons/tiktok-round.svg"
                  />
                </v-btn>
              </div>
            </v-sheet>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-card-title>
            {{ $t('wdyjbl') }}
          </v-card-title>

          <v-card-text class="text-center text-caption text-md-body-2">
            <v-container class="card_box_bg rounded">
              <v-row dense>
                <v-col cols="3">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="white--text"
                    >
                      {{ $t('wddj') }}
                    </v-col>
                    <v-col cols="12">
                      <v-sheet
                        rounded
                        dark
                        height="80"
                        class="mt-2 d-flex align-center justify-center bg-light-a2"
                      >
                        Lv{{ my_afflevel }}
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="px-2 white--text"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="5"
                          class="text-left"
                        >
                          {{ $t('yongjin') }}
                        </v-col>
                        <v-col>Tier 1</v-col>
                        <v-col>Tier 2</v-col>
                        <v-col>Tier 3</v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      cols="12"
                      class="py-2"
                    >
                      <v-sheet
                        rounded
                        dark
                        class="pa-2 bg-light-a2"
                      >
                        <v-row no-gutters>
                          <v-col
                            cols="5"
                            class="text-left"
                          >
                            {{ $t('tzyj') }}
                          </v-col>
                          <v-col>{{ my_betcommission.tier1 }}%</v-col>
                          <v-col>{{ my_betcommission.tier2 }}%</v-col>
                          <v-col>{{ my_betcommission.tier3 }}%</v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>

                    <v-col cols="12">
                      <v-sheet
                        rounded
                        dark
                        class="pa-2 bg-light-a2"
                      >
                        <v-row no-gutters>
                          <v-col
                            cols="5"
                            class="text-left"
                          >
                            {{ $t('ckyj') }}
                          </v-col>
                          <v-col>{{ my_depositcommission.tier1 }}%</v-col>
                          <v-col>{{ my_depositcommission.tier2 }}%</v-col>
                          <v-col>{{ my_depositcommission.tier3 }}%</v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-col>
        <v-col
          col="12"
          md="4"
        >
          <v-card-title>
            {{ $t('sjtj') }}
          </v-card-title>
          <v-card-text class="text-center text-caption text-md-body-2">
            <v-container class="rounded card_box_bg white--text">
              <div class="py-3">
                <div class="d-flex align-center justify-space-between">
                  <span>{{ $t('yxyq') }}</span>
                  <span>
                    <span class="blue--text">{{ total_invites }}</span>
                    / {{ nextlevel_require.total_invites }}
                  </span>
                </div>
                <v-progress-linear
                  rounded
                  :value="Math.round(total_invites/nextlevel_require.total_invites*100)"
                  color="blue"
                  class="my-1"
                />
              </div>
              <div class="pt-2 pb-3">
                <div class="d-flex align-center justify-space-between">
                  <span>{{ $t('ljyj') }}</span>
                  <span>
                    <span class="blue--text">R$ {{ total_award }}</span>
                    / R$ {{ nextlevel_require.total_commission }}
                  </span>
                </div>
                <v-progress-linear
                  rounded
                  :value="Math.round(total_award/nextlevel_require.total_commission*100)"
                  color="blue"
                  class="my-1"
                />
              </div>
            </v-container>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('yqjl') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('yxyq') }} = {{ $t('zcbljckdd') }}<strong class="mx-1 orange--text">R$ {{ model_invitationbonus.valid_invitation }}</strong>
      </v-card-subtitle>
      <v-card-text>
        <p>{{ $t('tgndyqlj') }}</p>
        
        <v-card
          flat
          color="card_box_bg rounded-b-0"
        >
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-account-plus"
                  class="mb-0 body-2"
                >
                  <div>{{ $t('yaoqing') }}{{ $t('ygyxyh') }}</div>
                  <strong class="yellow--text mr-2">+ R$ {{ model_invitationbonus['invite_1-5'] }}</strong><small>({{ $t('zuigao') }}R$ {{ model_invitationbonus['invite_50+'] }})</small>
                </v-alert>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-wallet-plus"
                  class="mb-0 body-2"
                >
                  <div>{{ $t('yhckcg') }}</div>
                  <strong class="yellow--text mr-2">+ R$ {{ model_invitationbonus.depositexceed200 }}</strong>
                </v-alert>
              </v-col>
              <!--
              <v-col
                cols="12"
                md="4"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-poker-chip"
                  class="mb-0 body-2"
                >
                  <div>{{ $t('yhtzcg') }}</div>
                  <strong class="yellow--text mr-2">+ R$ {{ model_invitationbonus.betexceed2000 }}</strong>
                </v-alert>
              </v-col>
              -->
            </v-row>
          </v-card-text>
        </v-card>

        <div
          class="overflow-x-auto"
          style="white-space: nowrap;"
        >
          <v-simple-table
            class="text-center app_bg rounded rounded-t-0"
          >
            <tbody>
              <tr>
                <td class="text-subtitle-2">
                  {{ $t('mryqrs') }}
                </td>
                <td>1 ～ 5</td>
                <td>6 ～ 15</td>
                <td>16 ～ 30</td>
                <td>31 ～ 50</td>
                <td>50+</td>
              </tr>
              <tr>
                <td class="text-subtitle-2">
                  {{ $t('khdjjmr') }}
                </td>
                <td>R$ {{ model_invitationbonus['invite_1-5'] }}</td>
                <td>R$ {{ model_invitationbonus['invite_6-15'] }}</td>
                <td>R$ {{ model_invitationbonus['invite_16-30'] }}</td>
                <td>R$ {{ model_invitationbonus['invite_31-50'] }}</td>
                <td>R$ {{ model_invitationbonus['invite_50+'] }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('yjms') }}
      </v-card-title>

      <v-card-text>
        <div
          class="text-center invite-lv d-flex d-sm-none"
        >
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 19%;"
          >
            {{ $t('ni') }}
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 40%;"
          >
            {{ $t('cengji') }} 1
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 64%;"
          >
            {{ $t('cengji') }} 2
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 90%;"
          >
            {{ $t('cengji') }} 3
          </div>
          <img
            src="../../assets/img/invite-lv-mobile.png"
            style="width: 80%;"
          >
        </div>

        <div
          class="text-center invite-lv d-none d-sm-flex"
        >
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 19%;"
          >
            Você
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 40%;"
          >
            Nível 1
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 64%;"
          >
            Nível 2
          </div>
          <div
            class="subtitle-1 invite-lv-text"
            style="top: 90%;"
          >
            Nível 3
          </div>
          <img
            src="../../assets/img/invite-lv-pc.png"
            style="width: 80%;"
          >
        </div>
      </v-card-text>
    </v-card>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('tghyjb') }}
      </v-card-title>
      <v-card-subtitle>
        * {{ $t('ljyjjkl') }}
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table
          class="box_bg"
        >
          <thead>
            <tr>
              <th>
                {{ $t('dengji') }}
              </th>
              <th>
                {{ $t('yxyq') }}
              </th>
              <th>
                {{ $t('ljyj') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in member_afflevel"
              :key="'L'+index"
            >
              <td>{{ item.title }}</td>
              <td>{{ item.total_invites }}</td>
              <td>R$ {{ item.total_commission }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('yjbl') }}
      </v-card-title>
      <v-card-subtitle>
        * {{ $t('ljyjjkl') }}
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table
          class="box_bg"
        >
          <thead>
            <tr>
              <th>
                {{ $t('dengji') }}
              </th>
              <th>
                {{ $t('guanxi') }}
              </th>
              <th>
                {{ $t('tzyj') }}
              </th>
              <th>
                {{ $t('ckyj') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(item, index) in model_betcommission"
            >
              <tr :key="'tr1'+index">
                <td rowspan="3">
                  Lv{{ item.afflevel }}
                </td>
                <td>Tier 1</td>
                <td>{{ item.tier1 }}%</td>
                <td>{{ model_depositcommission[index].tier1 }}%</td>
              </tr>
              <tr :key="'tr2'+index">
                <td>Tier 2</td>
                <td>{{ item.tier2 }}%</td>
                <td>{{ model_depositcommission[index].tier2 }}%</td>
              </tr>
              <tr :key="'tr3'+index">
                <td>Tier 3</td>
                <td>{{ item.tier3 }}%</td>
                <td>{{ model_depositcommission[index].tier3 }}%</td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-row
      class="mt-4 body-2"
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          flat
          color="box_bg"
        >
          <v-card-title>
            {{ $t('yqjjpm') }}
          </v-card-title>
          <v-list
            dense
            color="btn_bg"
          >
            <v-list-item
              v-for="(item, index) in top10_invite_award"
              :key="'T1'+index"
              class="ranking-list"
            >
              <v-list-item-avatar>
                <v-icon
                  v-if="index<3"
                  color="orange"
                >
                  mdi-numeric-{{ index+1 }}-circle
                </v-icon>
                <v-icon v-if="index>2">
                  mdi-numeric-{{ index+1 }}-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.username }}
                  <v-chip
                    small
                    dark
                    color="indigo"
                  >
                    Lv{{ item.afflevel }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  R$ 
                  <span class="orange--text">{{ item.total_invite_award }}</span>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          flat
          color="box_bg"
        >
          <v-card-title>
            {{ $t('yjjjpm') }}
          </v-card-title>
          <v-list
            dense
            color="btn_bg"
          >
            <v-list-item
              v-for="(item, index) in top10_commission"
              :key="'T2'+index"
              class="ranking-list"
            >
              <v-list-item-avatar>
                <v-icon
                  v-if="index<3"
                  color="orange"
                >
                  mdi-numeric-{{ index+1 }}-circle
                </v-icon>
                <v-icon v-if="index>2">
                  mdi-numeric-{{ index+1 }}-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.username }}
                  <v-chip
                    small
                    dark
                    color="indigo"
                  >
                    Lv{{ item.afflevel }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  R$ 
                  <span class="orange--text">{{ item.total_commission }}</span>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="justify-center"
    >
      <v-col
        cols="12"
        md="8"
      >
        <v-subheader>
          {{ $t('cjwd') }}
          <v-spacer />
          <v-btn
            plain
            to="/help"
          >
            <v-icon small>
              mdi-help-box
            </v-icon>
            {{ $t('help') }}
          </v-btn>
        </v-subheader>
        <v-list
          dense
          color="box_bg"
        >
          <v-list-item
            v-for="(h, i_h) in help_list"
            :key="'h_'+i_h"
            @click="showdetail(h)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ h.title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog
      v-model="detail"
      max-width="500"
      scrollable
    >
      <v-card
        color="box_bg"
      >
        <v-card-title class="text-h6">
          {{ chooseitem.title }}
          <v-spacer />
        </v-card-title>

        <v-card-text
          style="max-height: 400px;"
          v-html="chooseitem.content"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'InviteHome',
  components:{
  },
  props: {

  },
  data: () => ({
    member_id: 0,
    total_award: 0,
    total_betcommission: 0,
    total_depositcommission: 0,
    total_invite_award: 0,
    invite_code: '',
    invite_url: '',
    my_afflevel: 1,
    total_invites: 0,
    member_afflevel: {
        
    },
    model_betcommission: [
        
    ],
    model_depositcommission: [
        
    ],
    model_invitationbonus: {
      "valid_invitation":'',
      "depositexceed200":'',
      "betexceed2000":'',
      "invite_1-5":'',
      "invite_6-15":'',
      "invite_16-30":'',
      "invite_31-50":'',
      "invite_50+":''
    },
    my_betcommission: {
      "tier1":"",
      "tier2":"",
      "tier3":""
    },
    my_depositcommission: {
      "tier1":"",
      "tier2":"",
      "tier3":""
    },
    nextlevel_require: {
      "total_invites":'',
      "total_commission":''
    },
    top10_commission: [

    ],
    top10_invite_award: [

    ],
    help_list: [],
    detail: false,
    chooseitem: {},
    fburl: 'fb://',
    insurl: 'instagram://user?username=cbf_futebol',
    whatsappurl: 'whatsapp://send?',
    tturl: 'snssdk1180://user/profile/'
  }),
  computed: {

  },
  watch:{

  },
  created() {
    this.member_id = this.getStorage('member_id')

    if( (/iphone|ipod|ipad/.test( window.navigator.userAgent.toLowerCase() )) && (/safari/.test( window.navigator.userAgent.toLowerCase() ))==false ){
      this.fburl = "pgb://"+encodeURIComponent(this.fburl)
      this.insurl = "pgb://"+encodeURIComponent(this.insurl)
      this.whatsappurl = "pgb://"+encodeURIComponent(this.whatsappurl)
      this.tturl = "pgb://"+encodeURIComponent(this.tturl)
    }
  },
  mounted() {
    this.invite_home()
    this.helps()
  },
  methods: {
    invite_home() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.invite_home(paramObj).then((response) => {
        if(response.code==200){
          this.total_award = response.data.total_award
          this.total_betcommission = response.data.total_betcommission
          this.total_depositcommission = response.data.total_depositcommission
          this.total_invite_award = response.data.total_invite_award
          this.invite_code = response.data.invite_code
          this.invite_url = response.data.invite_url
          //this.invite_url = window.location.protocol+"//"+window.location.host+'/?code='+this.invite_code
          this.my_afflevel = response.data.my_afflevel
          this.total_invites = response.data.current_progress.total_invites
          
          this.member_afflevel = response.data.member_afflevel
          this.model_betcommission = response.data.model_betcommission
          this.model_depositcommission = response.data.model_depositcommission
          this.model_invitationbonus = response.data.model_invitationbonus
          this.my_betcommission = response.data.my_betcommission
          this.my_depositcommission = response.data.my_depositcommission
          this.nextlevel_require = response.data.nextlevel_require
          this.top10_commission = response.data.top10_commission
          this.top10_invite_award = response.data.top10_invite_award
        }else{
          
        }
      })
    },
    helps() {
      let paramObj = {
        category: 'commission'
      }
      this.$server.helps(paramObj).then((response) => {
        if(response.code==200){
          this.help_list = response.data.helps
        }else{
          
        }
      })
    },
    showdetail(item) {
      this.chooseitem = item
      this.detail = true
    },
    closedetail() {
      this.chooseitem = {}
      this.detail = false
    },
    changechip(tab) {
      this.$emit("changechip", tab)
    },
    doCopy: function (text) {
      this.$copyText(text).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('fzcg'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>

<style>
.ranking-list:nth-child(2n) {
  background: var(--v-box_bg-base);
}
.invite-lv{
  width: 100%;
  overflow: hidden;
  margin-top: 0.16rem;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.invite-lv-text{
  position: absolute;
}
</style>