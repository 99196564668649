<template>
  <div>
    <v-row dense>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>{{ $t('zckyj') }}</small>
          <div>
            <small>R$</small>
            <strong class="ml-1 text--primary">{{ deposit.total_depositcommission }}</strong>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>Tier 1</small>
          <div>
            <small>R$</small>
            <strong class="ml-1 text--primary">{{ deposit.tier1_depositcommission }}</strong>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>Tier 2</small>
          <div>
            <small>R$</small>
            <strong class="ml-1 text--primary">{{ deposit.tier2_depositcommission }}</strong>
          </div>
        </v-sheet>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-sheet
          rounded
          color="box_bg"
          class="text-center pa-3 text--disabled"
        >
          <small>Tier 3</small>
          <div>
            <small>R$</small>
            <strong class="ml-1 text--primary">{{ deposit.tier3_depositcommission }}</strong>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-card
      flat
      color="box_bg"
      class="mt-3"
    >
      <v-card-text>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateRangeText"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="$t('yjrq')"
                  readonly
                  v-bind="attrs"
                  dense
                  outlined
                  hide-details
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                scrollable
                range
                color="indigo"
                locale="pt-BR"
                class="box_bg"
              >
                <v-btn
                  text
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="6"
            md="3"
          >
            <v-btn
              depressed
              color="primary"
              @click="invite_deposit_list"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              {{ $t('sousuo') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <div
        class="overflow-x-auto"
        style="white-space: nowrap;"
      >
        <div
          v-if="list.length==0"
          class="text-center text--disabled my-10"
        >
          <v-icon
            x-large
            class="text--disabled"
          >
            mdi-archive-search-outline
          </v-icon>
          <small class="d-block text-center">{{ $t('myrhjl') }}</small>
        </div>
        <v-simple-table v-if="list.length>0">
          <thead class="nav_bg">
            <tr>
              <th>{{ $t('yjrq') }}</th>
              <!--<th>{{ $t('ckrs') }}</th>-->
              <th>{{ $t('zcke') }}(R$)</th>
              <th>{{ $t('zckyj') }}(R$)</th>
              <th>Tier1(R$)</th>
              <th>Tier2(R$)</th>
              <th>Tier3(R$)</th>
              <th>{{ $t('lqsj') }}</th>
              <th>{{ $t('beizhu') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in list"
              :key="'b'+index"
            >
              <td>{{ item.day }}</td>
              <!--<td>{{ item.deposit_num }}</td>-->
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.deposit_amount }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                <strong class="orange--text">{{ item.total_depositcommission }}</strong>
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.tier1_depositcommission }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.tier2_depositcommission }}
              </td>
              <td>
                <small class="mr-1 text--disabled">R$</small>
                {{ item.tier3_depositcommission }}
              </td>
              <td>{{ item.received_time?$dayjs.unix(item.received_time).format('DD/MM/YYYY HH:mm'):'' }}</td>
              <td>-</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-pagination
            v-model="page"
            :length="pagecount"
            :total-visible="7"
            @input="invite_deposit_list"
          />
          <v-spacer />
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'InviteDeposit',
  components:{
  },
  props: {

  },
  data: () => ({
    deposit: {
      "tier1_depositcommission": 0,
      "tier2_depositcommission": 0,
      "tier3_depositcommission": 0,
      "total_depositcommission": 0
    },
    menu: false,
    dates: [],
    page: 1,
    pagecount: 1,
    list: [

    ]
  }),
  computed: {
    dateRangeText:{
      get: function () {
        return this.dates.join(' ~ ')
      },
      set: function (newVal) {

      }
    }
  },
  watch:{
    dateRangeText: {
      handler(newVal, oldVal) {
        this.dates.sort()
      }
    },
  },
  created() {
    this.invite_deposit()
  },
  mounted() {
    this.invite_deposit_list()
  },
  methods: {
    invite_deposit() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.invite_deposit(paramObj).then((response) => {
        if(response.code==200){
          this.deposit = response.data
        }else{
          
        }
      })
    },
    invite_deposit_list() {
      let paramObj = {
        page:this.page,
        member_id: this.getStorage('member_id'),
        startday: this.dates[0],
        endday: this.dates[1],
      }
      this.$server.invite_deposit_list(paramObj).then((response) => {
        if(response.code==200){
          this.pagecount = response.data.last_page
          this.list = response.data.data
        }else{
          
        }
      })
    }
  },
};
</script>