<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card
          flat
          color="card_box_bg"
        >
          <v-card-text>
            <v-row dense>
              <v-col
                cols="12"
                md="6"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-cash-plus"
                  class="mb-0 body-2"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <div>{{ $t('ktyj') }}</div>
                      R$ 
                      <strong class="yellow--text mr-1">{{ totaldata.commissionable }}</strong>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        :disabled="totaldata.commissionable==0?true:false"
                        @click="withdraw_commission"
                      >
                        {{ $t('lingqu') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-cash-multiple"
                  class="mb-0 body-2"
                >
                  <div>{{ $t('ljyj') }}</div>
                  R$ 
                  <strong class="yellow--text mr-1">{{ totaldata.total_commission }}</strong>
                </v-alert>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-alert
                  dense
                  text
                  prominent
                  dark
                  icon="mdi-cash-check"
                  class="mb-0 body-2"
                >
                  <div>{{ $t('ytyj') }}</div>
                  R$ 
                  <strong class="yellow--text mr-1">{{ totaldata.withdrawned }}</strong>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        md="3"
      >
        <v-card
          flat
          color="box_bg"
          class="text-center"
        >
          <v-card-text>
            <div>{{ $t('zyqs') }}</div>
            <strong class="mr-1">{{ totaldata.total_invites }}</strong><small class="text--disabled">{{ $t('ren') }}</small>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-card
          flat
          color="box_bg"
          class="text-center"
        >
          <v-card-text>
            <div>{{ $t('yjrs') }}</div>
            <strong class="mr-1">{{ totaldata.tier1_invites }}</strong><small class="text--disabled">{{ $t('ren') }}</small>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-card
          flat
          color="box_bg"
          class="text-center"
        >
          <v-card-text>
            <div>{{ $t('ejrs') }}</div>
            <strong class="mr-1">{{ totaldata.tier2_invites }}</strong><small class="text--disabled">{{ $t('ren') }}</small>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <v-card
          flat
          color="box_bg"
          class="text-center"
        >
          <v-card-text>
            <div>{{ $t('sjrs') }}</div>
            <strong class="mr-1">{{ totaldata.tier3_invites }}</strong><small class="text--disabled">{{ $t('ren') }}</small>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-4 overflow-x-auto">
      <v-btn-toggle
        v-model="daterange"
        dense
        group
        mandatory
        color="primary"
      >
        <v-btn>
          {{ $t('quanbu') }}
        </v-btn>
        <v-btn>
          {{ $t('zuori') }}
        </v-btn>
        <v-btn>
          {{ $t('benzhou') }}
        </v-btn>
        <v-btn>
          {{ $t('benyue') }}
        </v-btn>
        <v-btn>
          {{ $t('shangyue') }}
        </v-btn>
      </v-btn-toggle>
    </div>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('yqjj') }}
        <v-spacer />
        <v-btn
          icon
          small
          @click="changechip('award')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            md="3"
          >
            <v-card
              flat
              color="app_bg"
            >
              <v-card-text>
                <div>{{ $t('zyqjj') }}</div>
                <small class="text--disabled mr-1">R$</small>
                <strong class="orange--text">{{ rangedata.invite_award }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('yxyq') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.reg_valid_award }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('cunkuan') }} >200</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.depositexceed200_award }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      color="box_bg"
      class="mt-4"
    >
      <v-card-title>
        {{ $t('tzyj') }}
        <v-spacer />
        <v-btn
          icon
          small
          @click="changechip('bet')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="app_bg"
            >
              <v-card-text>
                <div>{{ $t('tzyj') }}</div>
                <small class="text--disabled mr-1">R$</small>
                <strong class="orange--text">{{ rangedata.total_betcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('yjyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier1_betcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('ejyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier2_betcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('sjyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier3_betcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        {{ $t('ckyj') }}
        <v-spacer />
        <v-btn
          icon
          small
          @click="changechip('deposit')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="app_bg"
            >
              <v-card-text>
                <div>{{ $t('zckyj') }}</div>
                <small class="text--disabled mr-1">R$</small>
                <strong class="orange--text">{{ rangedata.total_depositcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('yjyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier1_depositcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('ejyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier2_depositcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="6"
            md="3"
          >
            <v-card
              flat
              color="nav_bg"
            >
              <v-card-text>
                <div>{{ $t('sjyj') }}</div>
                <small class="text--disabled">R$</small>
                <strong class="ml-1">{{ rangedata.tier3_depositcommission }}</strong>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: 'InviteDashboard',
  components:{
  },
  props: {

  },
  data: () => ({
    daterange: 0,
    startday: '',
    endday: '',

    totaldata: {
      commissionable: 0,
      tier1_invites: 0,
      tier2_invites: 0,
      tier3_invites: 0,
      total_commission: 0,
      total_invites: 0,
      withdrawned: 0,
    },
    rangedata: {
      reg_valid_award: 0,
      depositexceed200_award: 0,
      tier1_betcommission: 0,
      tier2_betcommission: 0,
      tier3_betcommission: 0,
      tier1_depositcommission: 0,
      tier2_depositcommission: 0,
      tier3_depositcommission: 0,
      invite_award: 0,
      total_betcommission: 0,
      total_depositcommission: 0
    },
    showprogress: false
  }),
  computed: {

  },
  watch:{
    daterange: {
      handler(newVal, oldVal) {
        if(newVal==0){
          this.startday = '2023-01-01'
          this.endday = this.$dayjs().format('YYYY-MM-DD')
        }
        if(newVal==1){
          this.startday = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')
          this.endday = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        }
        if(newVal==2){
          this.startday = this.$dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD')
          this.endday = this.$dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
        }
        if(newVal==3){
          this.startday = this.$dayjs().startOf('month').format('YYYY-MM-DD')
          this.endday = this.$dayjs().endOf('month').format('YYYY-MM-DD')
        }
        if(newVal==4){
          this.startday = this.$dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')
          this.endday = this.$dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')
        }
        this.invite_dashboard_byday()
      }
    },
    showprogress (val) {
      val && setTimeout(() => {
        this.showprogress = false
      }, 5000)
    },
  },
  created() {
    this.startday = '2023-01-01'
    this.endday = this.$dayjs().format('YYYY-MM-DD')
  },
  mounted() {
    this.invite_dashboard()
    this.invite_dashboard_byday()
  },
  methods: {
    invite_dashboard() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.invite_dashboard(paramObj).then((response) => {
        if(response.code==200){
          this.totaldata = response.data
        }else{
          
        }
      })
    },
    invite_dashboard_byday() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
        startday: this.startday,
        endday: this.endday
      }
      this.$server.invite_dashboard_byday(paramObj).then((response) => {
        if(response.code==200){
          this.rangedata = response.data
        }else{
          
        }
      })
    },
    withdraw_commission() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.withdraw_commission(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.invite_dashboard()
          this.invite_dashboard_byday()
        }else{
          
        }
      })
    },
    changechip(tab) {
      this.$emit("changechip", tab)
    },
  },
};
</script>